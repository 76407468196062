import React from "react";
import { graphql } from "gatsby";
import '../../style/general-page.scss';
import GeneralPageHeader from "../../components/GeneralPage/GeneralPageHeader";
import GeneralContent from "../../components/GeneralPage/GeneralContent";
import Layout from "../../components/Layout";

export default function SubPagesIndexPage({ data }) {

  const { secondHeading, firstHeading, description, heroImage, textHeading, textDescription } = data;

  return (
    <Layout>
      <main>
        <section className="general-page">
          <GeneralPageHeader
            firstHeading={firstHeading}
            secondHeading={secondHeading}
            description={description}
            heroImage={heroImage}
          />

        </section>
        <GeneralContent title={textHeading} descriptionText={textDescription} />

      </main>
    </Layout>
  );
}


export const subPageQuery = graphql`
    query SubPageQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "sub-page" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  subtitle
                  descriptionHeading
                  descriptionText
                  heroImage {
                    childImageSharp {
                      gatsbyImageData(quality: 100,  placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
`;
